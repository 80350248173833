document.addEventListener("DOMContentLoaded", function () {
    // Fonction pour basculer la sélection de la case à cocher en cliquant sur l'image
    function toggleCheckboxOnImageClick() {
        const frequentlyBoughtImages = document.querySelectorAll(
            ".frequently-bought-together-product img"
        );

        frequentlyBoughtImages.forEach(function (image) {
            image.addEventListener("click", function () {
                const checkbox = this.closest(
                    ".frequently-bought-together-product"
                ).querySelector('input[type="checkbox"]');
                checkbox.checked = !checkbox.checked; // Inverser l'état de la case à cocher
                checkbox.dispatchEvent(new Event("change")); // Déclencher l'événement change
            });
        });
    }

    // Appelle cette fonction après avoir chargé les images
    toggleCheckboxOnImageClick();

    // Fonction pour mettre à jour le champ caché avec les IDs des produits sélectionnés
    function updateSelectedProductIds() {
        const selectedIds = [];
        const checkboxes = document.querySelectorAll(".product-checkbox");

        checkboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
                selectedIds.push(checkbox.getAttribute("data-id"));
            }
        });

        const hiddenInputContainer = document.getElementById("recommendedProductIds");
        if (hiddenInputContainer) {
            hiddenInputContainer.innerHTML = ""; // On vide les valeurs précédentes

            selectedIds.forEach(function (id) {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = "recommendedProductIds[]";
                input.value = id;
                hiddenInputContainer.appendChild(input);
            });
        }

        ensureAtLeastOneChecked(checkboxes, updateSelectedProductIds);
    }

    // Fonction pour les maintenances payantes
    function updateSelectedMaintenanceIds() {
        const selectedIds = [];
        const maintenanceCheckboxes = document.querySelectorAll('.maintenance-checkbox');

        maintenanceCheckboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
                selectedIds.push(checkbox.getAttribute('data-id'));
            }
        });

        const hiddenMaintenanceInputContainer = document.getElementById('maintenanceProductIds');
        if (hiddenMaintenanceInputContainer) {
            hiddenMaintenanceInputContainer.innerHTML = ''; // On vide les valeurs précédentes

            selectedIds.forEach(function (id) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = 'maintenanceProductIds[]';
                input.value = id;
                hiddenMaintenanceInputContainer.appendChild(input);
            });
        }

        ensureAtLeastOneChecked(maintenanceCheckboxes, updateSelectedMaintenanceIds);
    }

    // Fonction pour empêcher de décocher toutes les cases
    function ensureAtLeastOneChecked(checkboxes, updateFunction) {
        const checkedBoxes = Array.from(checkboxes).filter((cb) => cb.checked);

        if (checkedBoxes.length === 0 && checkboxes.length > 0) {
            checkboxes[0].checked = true; // Coche la première case par défaut

            // Appel de la fonction de mise à jour après avoir réactivé la première case
            updateFunction();
        }
    }

    // Appel initial pour l'état des produits et maintenances sélectionnés
    updateSelectedProductIds();
    updateSelectedMaintenanceIds();

    // Ajout de l'écouteur d'événements sur chaque checkbox
    document.querySelectorAll('.product-checkbox').forEach(function (checkbox) {
        checkbox.addEventListener('change', updateSelectedProductIds);
    });

    document.querySelectorAll('.maintenance-checkbox').forEach(function (checkbox) {
        checkbox.addEventListener('change', updateSelectedMaintenanceIds);
    });

    // Fonction pour ouvrir les onglets
    function openTab(evt, tabName) {
        const tabcontent = document.getElementsByClassName("tabcontent");
        for (let i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        const tablinks = document.getElementsByClassName("tablinks");
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        document.getElementById(tabName).style.display = "block";
        evt.currentTarget.className += " active";
    }

    // Fonction pour scroller vers un élément avec un offset pour la barre de navigation
    function scrollToElement(elementId, duration) {
        const element = document.getElementById(elementId);
        const targetPosition = element.offsetTop - 180; // Compense la hauteur de la barre de navigation
        const startPosition = window.pageYOffset;
        const distance = targetPosition - startPosition;
        let startTime = null;

        function animationScroll(currentTime) {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const progress = Math.min(timeElapsed / duration, 1);
            const ease = easeInOutQuad(progress);

            window.scrollTo(0, startPosition + distance * ease);

            if (timeElapsed < duration) requestAnimationFrame(animationScroll);
        }

        function easeInOutQuad(t) {
            return t < 0.5 ? 2 * t * t : 1 - Math.pow(-2 * t + 2, 2) / 2;
        }

        requestAnimationFrame(animationScroll);
    }

    // Gestion du lien pour "Voir les fiches techniques"
    const productSheetLink = document.querySelector(".product-sheet-link");
    if (productSheetLink) {
        productSheetLink.addEventListener("click", function (event) {
            event.preventDefault();
            scrollToElement("technicalTabButton", 1500);
            document.getElementById("technicalTabButton").click();
        });
    }

    // Ouvrir l'onglet approprié lors du chargement de la page
    const longDescriptionElement = document.getElementById("longDescription");
    const longDescriptionTabButton = document.querySelector(
        'button[data-tab="longDescription"]'
    );

    const technicalDescriptionElement = document.getElementById(
        "technicalDescription"
    );
    const technicalDescriptionTabButton = document.querySelector(
        'button[data-tab="technicalDescription"]'
    );

    const sheetPdfElement = document.getElementById("sheetPdf");
    const sheetPdfTabButton = document.querySelector(
        'button[data-tab="sheetPdf"]'
    );

    if (longDescriptionElement && longDescriptionTabButton) {
        longDescriptionElement.style.display = "block";
        longDescriptionTabButton.className += " active";
    } else if (technicalDescriptionElement && technicalDescriptionTabButton) {
        technicalDescriptionElement.style.display = "block";
        technicalDescriptionTabButton.className += " active";
    } else if (sheetPdfElement && sheetPdfTabButton) {
        sheetPdfElement.style.display = "block";
        sheetPdfTabButton.className += " active";
    }

    // Gestion du clic pour les boutons d'onglet
    const tabButtons = document.querySelectorAll(".tab button");
    tabButtons.forEach((button) => {
        button.addEventListener("click", function (event) {
            openTab(event, button.getAttribute("data-tab"));
        });
    });

    // Changement dynamique d'image principale au passage de la souris sur une image associée
    const relatedImages = document.querySelectorAll(".related-product-image");
    const mainProductImage = document.getElementById("mainProductImage");

    relatedImages.forEach(function (image) {
        image.addEventListener("mouseover", function () {
            mainProductImage.src = image.getAttribute("data-img-src");
        });
    });
    
// Fonction pour limiter la sélection à une seule case cochée et empêcher de décocher la dernière checkbox cochée
const limitToOneSelectionWithMinOne = (checkboxClass) => {
    const checkboxes = document.querySelectorAll(checkboxClass);

    // Cocher uniquement la première checkbox au chargement de la page
    if (checkboxes.length > 0) {
        checkboxes.forEach((checkbox, index) => {
            checkbox.checked = index === 0; // Coche la première, décoche les autres
        });
    }

    checkboxes.forEach((checkbox) => {
        checkbox.addEventListener("change", function () {
            const checkedBoxes = Array.from(checkboxes).filter((cb) => cb.checked);

            // Si la case est cochée, décocher les autres
            if (this.checked) {
                checkboxes.forEach((cb) => {
                    if (cb !== this) cb.checked = false;
                });
            }

            // Empêche de décocher la dernière checkbox cochée
            if (checkedBoxes.length === 0) {
                checkbox.checked = true;
            }
        });
    });
};

// Appliquer la limitation pour les sections "devis" et "maintenance"
limitToOneSelectionWithMinOne(".devis-checkbox");
limitToOneSelectionWithMinOne(".maintenance-devis-checkbox");


    // Mise à jour des liens pour les produits sur devis
    const devisCheckboxes = document.querySelectorAll(".devis-checkbox");
    const devisLink = document.getElementById("devis-link");

    devisCheckboxes.forEach(function (checkbox) {
        checkbox.addEventListener("change", function () {
            if (this.checked) {
                devisLink.setAttribute(
                    "href",
                    `/demande-devis?product=${this.getAttribute("data-id")}`
                );
            }
        });
    });

    // Mise à jour des liens pour les maintenances sur devis
    const maintenanceCheckboxes = document.querySelectorAll(
        ".maintenance-devis-checkbox"
    );
    const maintenanceLink = document.getElementById("maintenance-link");

    maintenanceCheckboxes.forEach(function (checkbox) {
        checkbox.addEventListener("change", function () {
            if (this.checked) {
                maintenanceLink.setAttribute(
                    "href",
                    `/demande-devis?maintenance=${this.getAttribute("data-id")}`
                );
            }
        });
    });

    // Recalcul dynamique des prix TTC et HT pour les produits fréquemment achetés ensemble
    const productCheckboxes = document.querySelectorAll(".product-checkbox");
    const totalTtcElement = document.getElementById("total-price");
    const totalHtElement = document.getElementById("total-price-ht");

    function calculateTotalPrices(checkboxes) {
        let totalTtc = 0;
        let totalHt = 0;

        checkboxes.forEach(function (checkbox) {
            if (checkbox.checked) {
                const priceTtc = parseFloat(checkbox.getAttribute("data-price")) || 0;
                const htFactor = parseFloat(checkbox.getAttribute("data-ht-factor")) || 1;
                const priceHt = priceTtc / htFactor;

                totalTtc += priceTtc;
                totalHt += priceHt;
            }
        });

        if (totalTtcElement) {
            totalTtcElement.innerText = totalTtc.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + " € TTC";
        }
        if (totalHtElement) {
            totalHtElement.innerText = totalHt.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + " € HT";
        }
    }

    productCheckboxes.forEach(function (checkbox) {
        checkbox.addEventListener("change", function () {
            calculateTotalPrices(productCheckboxes);
        });
    });

    // Recalcul dynamique des prix TTC et HT pour les maintenances payantes
    const maintenanceCheckboxesForCalc = document.querySelectorAll(
        ".maintenance-checkbox"
    );
    const totalMaintenanceTtcElement = document.getElementById(
        "total-maintenance-price"
    );
    const totalMaintenanceHtElement = document.getElementById(
        "total-maintenance-price-ht"
    );

    function calculateMaintenancePrices() {
        let totalTtc = 0;
        let totalHt = 0;

        maintenanceCheckboxesForCalc.forEach(function (checkbox) {
            if (checkbox.checked) {
                const priceTtc = parseFloat(checkbox.getAttribute("data-price")) || 0;
                const htFactor = parseFloat(checkbox.getAttribute("data-ht-factor")) || 1;
                const priceHt = priceTtc / htFactor;

                totalTtc += priceTtc;
                totalHt += priceHt;
            }
        });

        if (totalMaintenanceTtcElement) {
            totalMaintenanceTtcElement.innerText = totalTtc.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + " € TTC";
        }
        if (totalMaintenanceHtElement) {
            totalMaintenanceHtElement.innerText = totalHt.toLocaleString('fr-FR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }) + " € HT";
        }
    }

    maintenanceCheckboxesForCalc.forEach(function (checkbox) {
        checkbox.addEventListener("change", calculateMaintenancePrices);
    });

    calculateTotalPrices(productCheckboxes);
    calculateMaintenancePrices();
});

document.addEventListener("DOMContentLoaded", function () {
    const faqTitles = document.querySelectorAll(".faq-container-title");

    faqTitles.forEach((title) => {
        title.addEventListener("click", function () {
            const answer = this.nextElementSibling;

            // Vérifie si la réponse est déjà visible
            const isAnswerVisible = answer.style.display === "block";

            // Ferme toutes les réponses
            document.querySelectorAll(".faq-question-answer").forEach((answer) => {
                answer.style.display = "none";
            });

            // Si la réponse était masquée, la rend visible
            if (!isAnswerVisible) {
                answer.style.display = "block";
            }
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    // Sélectionne toutes les descriptions ayant potentiellement des liens
    document.querySelectorAll(".tabcontent a").forEach(link => {
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener noreferrer"); // Sécurité supplémentaire
    });
});
