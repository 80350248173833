// Lorsque le document est complètement chargé, exécuter la fonction suivante
// When the document is fully loaded, execute the following function
document.addEventListener('DOMContentLoaded', function() {
        // Sélectionne tous les éléments avec la classe 'caroussel-assurance'
        // Select all elements with the class 'caroussel-assurance'
    let items = document.querySelectorAll('.caroussel-assurance');
    let currentItem = 0;
    const totalItems = items.length;

    // Fonction pour afficher l'élément courant du carrousel
    // Function to display the current item of the carousel
    function showCurrentItem() {
        items.forEach((item, index) => {
            item.classList.remove('active');
            if(index === currentItem) {
                item.classList.add('active');
            }
        });
    }

    // Fonction pour passer à l'élément suivant dans le carrousel
    // Function to move to the next item in the carousel
    function nextItem() {
        currentItem = (currentItem + 1) % totalItems;
        showCurrentItem();
    }

    // Affiche l'élément initial (le premier)
    // Display the initial item (the first one)
    showCurrentItem();
    // Change automatiquement l'élément affiché toutes les 5 secondes
    // Automatically change the displayed item every 5 seconds
    setInterval(nextItem, 5000);
});
