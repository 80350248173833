document.addEventListener("DOMContentLoaded", function() {
    
    /**
     * Ouvre un onglet spécifique en masquant les autres onglets et met à jour l'URL.
     * @param {Event} evt - L'événement de clic déclencheur.
     * @param {string} tabName - Le nom de l'onglet à afficher.
     */
    window.openTabPersonalInformation = function(evt, tabName) {

        // Masquer tous les contenus des onglets
        const tabcontent = document.getElementsByClassName("tabcontent-personal-information");
        for (let i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }

        // Retirer la classe active de tous les boutons des onglets
        const tablinks = document.getElementsByClassName("tablinks-personal-information");
        for (let i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }

        // Afficher le contenu de l'onglet sélectionné
        document.getElementById(tabName).style.display = "block";

        // Ajouter la classe active au bouton de l'onglet sélectionné
        if (evt) {
            evt.currentTarget.className += " active";
        }

        // Mettre à jour l'URL sans provoquer de défilement
        history.pushState(null, null, `#${tabName}`);

        // Remonter automatiquement en haut de la page avec décalage de 150px pour la navbar
        const offset = 150; // Hauteur de la navbar
        setTimeout(() => {
            window.scrollTo({
                top: 0 - offset,
                behavior: "smooth"
            });
        }, 50); // Attendre que tout soit rendu avant de scroller
    };

    /**
     * Initialisation de l'onglet actif lors du chargement de la page.
     */
    const hash = window.location.hash.substring(1); // Récupérer le hash sans le '#'
    if (hash) {
        const tabLink = document.querySelector(`[onclick="openTabPersonalInformation(event, '${hash}')"]`);
        if (tabLink) {
            tabLink.click(); // Simuler un clic sur le lien de l'onglet
        } else {
            openTabPersonalInformation(null, hash); // Ouvrir directement l'onglet si aucun lien n'est trouvé
        }
    } else {
        const defaultTab = document.getElementById("defaultOpen");
        if (defaultTab) {
            defaultTab.click(); // Simuler un clic sur l'onglet par défaut
        }
    }

    /**
     * Fonction pour afficher ou masquer le formulaire d'édition d'adresse.
     * @param {string} addressId - L'ID de l'adresse à modifier.
     */
    window.showEditForm = function(addressId) {
        const form = document.getElementById('edit-form-' + addressId);
        form.style.display = (form.style.display === 'none' || form.style.display === '') ? 'block' : 'none';
    };

    /**
     * Fonction pour charger ou cacher un formulaire d'édition d'adresse via AJAX.
     * @param {string} addressId - L'ID de l'adresse à modifier.
     */
    window.toggleEditForm = function(addressId) {
        const container = document.getElementById('edit-form-container-' + addressId);
    
        // Fermer les autres formulaires ouverts
        document.querySelectorAll('.edit-address-form').forEach(form => {
            if (form !== container) {
                form.innerHTML = ""; // Vider les autres formulaires
            }
        });
    
        if (container.innerHTML.trim() === "") {
            // Charger le formulaire via AJAX
            fetch(`/adresse/modifier/${addressId}`, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            .then(response => {
                if (!response.ok) {
                    console.error(`Erreur lors du chargement : ${response.status}`);
                    return response.text().then(html => {
                        console.error("Réponse serveur :", html);
                        throw new Error(`Erreur serveur : ${response.status}`);
                    });
                }
                return response.text();
            })
            .then(html => {
                container.innerHTML = html; // Injecter le formulaire dans le conteneur
            })
            .catch(error => {
                console.error('Erreur lors du chargement du formulaire :', error);
            });
        } else {
            container.innerHTML = ""; // Vider le conteneur si déjà ouvert
        }
    };    

    /**
     * Fonction pour remonter automatiquement après suppression ou modification d'une adresse.
     */
    window.afterAddressAction = function() {
        const offset = 150; // Hauteur de la navbar
        setTimeout(() => {
            window.scrollTo({
                top: 0 - offset,
                behavior: "smooth"
            });
        }, 50); // Attendre que tout soit rendu avant de scroller
    };

    /**
     * Forcer le scroll en haut après soumission du formulaire.
     */
    if (performance.navigation.type === 1) {
        const offset = 150; // Hauteur de la navbar
        setTimeout(() => {
            window.scrollTo({
                top: 0 - offset,
                behavior: "smooth"
            });
        }, 50);
    }
});
