import './bootstrap.js';
import { startStimulusApp } from '@symfony/stimulus-bridge';

// Démarre Stimulus
startStimulusApp();

/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import './styles/libre-acces-js/general-js/_header.js';
import './styles/libre-acces-js/general-js/_assurance-footer.js';
import './styles/libre-acces-js/general-js/_navbar.js';
import './styles/libre-acces-js/general-js/_footer.js';
import './styles/libre-acces-js/home-js/_shop-product-display.js';
import './styles/libre-acces-js/home-js/_financial-help.js';
import './styles/libre-acces-js/home-js/_filtered-product-home.js';
import './styles/libre-acces-js/home-js/_shop-product-subcategory.js';
import './styles/libre-acces-js/home-js/_filtered-handicap.js';
import './styles/libre-acces-js/home-js/_product-display-on-quote-home.js';
import './styles/libre-acces-js/home-js/_blog-display-home.js';
import './styles/libre-acces-js/home-js/_latest-articles.js';
import './styles/libre-acces-js/product-sheet-js/_display-productsheet.js';
import './styles/libre-acces-js/forms-js/_contact.js';
import './styles/libre-acces-js/forms-js/_quote-request.js';
import './styles/libre-acces-js/forms-js/_SAV-request.js';
import './styles/libre-acces-js/forms-js/_registration.js';
import './styles/libre-acces-js/product-shop-js/_display-product-shop.js';
import './styles/libre-acces-js/home-blog-js/_home-blog.js';
import './styles/libre-acces-js/blog-post-page-js/_blog-post-page.js';
import './styles/libre-acces-js/personal-information-js/_personal-information.js';
import './styles/libre-acces-js/general-js/_scroll-utils.js';
import './styles/libre-acces-js/general-js/_menu-burger.js';
