document.addEventListener('DOMContentLoaded', function() {
    function initQuoteForm() {
        var productSection = document.querySelector('.section-product');
        var maintenanceSection = document.querySelector('.section-maintenance');
        var requestTypeInputs = document.querySelectorAll('input[name="quote_request[requestType]"]');
        var productSelect = document.querySelector('select[name="quote_request[products]"]');
        var maintenanceSelect = document.querySelector('select[name="quote_request[maintenances]"]');
        

        // Fonction pour obtenir les paramètres de l'URL
        function getUrlParameter(name) {
            name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
            var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
            var results = regex.exec(location.search);
            return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
        }

        // Fonction pour réinitialiser un select
        function resetSelect(selectElement) {
            if (selectElement) {
                selectElement.value = '';
            }
        }

        // Fonction pour afficher ou masquer les sections selon le type de demande
        function toggleSections() {
            var selectedRequestTypeInput = document.querySelector('input[name="quote_request[requestType]"]:checked');
            if (!selectedRequestTypeInput) return;

            var selectedRequestType = selectedRequestTypeInput.value;

            if (selectedRequestType === 'product') {
                productSection.classList.remove('hidden-quote-section');
                maintenanceSection.classList.add('hidden-quote-section');
                resetSelect(maintenanceSelect); // Réinitialiser la sélection de maintenance
            } else if (selectedRequestType === 'maintenance') {
                productSection.classList.add('hidden-quote-section');
                maintenanceSection.classList.remove('hidden-quote-section');
                resetSelect(productSelect); // Réinitialiser la sélection de produit
            }
        }

        var preSelectedProduct = getUrlParameter('product');
        var preSelectedMaintenance = getUrlParameter('maintenance');

        // Si un produit est passé dans l'URL, forcer la sélection sur "Produit sur devis"
        if (preSelectedProduct) {
            var productRequestTypeInput = document.querySelector('input[name="quote_request[requestType]"][value="product"]');
            if (productRequestTypeInput) {
                productRequestTypeInput.checked = true;
            }
        }

        // Si une maintenance est passée dans l'URL, forcer la sélection sur "Maintenance"
        if (preSelectedMaintenance) {
            var maintenanceRequestTypeInput = document.querySelector('input[name="quote_request[requestType]"][value="maintenance"]');
            if (maintenanceRequestTypeInput) {
                maintenanceRequestTypeInput.checked = true;
            }
        }

        // Si aucun paramètre n'est passé dans l'URL, réinitialiser sur "Produit sur devis"
        if (!preSelectedProduct && !preSelectedMaintenance) {
            var defaultRequestTypeInput = document.querySelector('input[name="quote_request[requestType]"][value="product"]');
            if (defaultRequestTypeInput) {
                defaultRequestTypeInput.checked = true;
            }
            localStorage.setItem('lastSelectedRequestType', 'product');
        }

        toggleSections();

        requestTypeInputs.forEach(input => {
            input.addEventListener('change', function() {
                toggleSections();
            });
        });
    }

    initQuoteForm();
});
