document.addEventListener('DOMContentLoaded', function() {
    var subCategorySelect = document.querySelector('select[name="sav_request[subCategory]"]');
    var productSelect = document.querySelector('select[name="sav_request[productConcerned]"]');

    if (subCategorySelect && productSelect) {
        subCategorySelect.addEventListener('change', function() {
            var subCategoryId = this.value;

            fetch('/api/products/' + subCategoryId)
                .then(response => response.json())
                .then(data => {
                    // Réinitialiser la liste des produits
                    productSelect.innerHTML = '<option value="">Sélectionnez un produit</option>';
                    data.forEach(function(product) {
                        var option = document.createElement('option');
                        option.value = product.id;
                        option.text = product.name;
                        productSelect.add(option);
                    });
                });
        });
    }
});
