document.addEventListener('DOMContentLoaded', function () {
    const postsPerPage = 6; // Nombre d'articles par page

    // Fonction pour un défilement fluide vers le haut
    function slowScrollToTopBlog(duration) {
        const startPosition = window.scrollY;
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const easeProgress = 1 - Math.pow(1 - progress, 3);

            window.scrollTo(0, startPosition * (1 - easeProgress));

            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        requestAnimationFrame(animateScroll);
    }

    // Fonction principale pour gérer la pagination
    function initializePagination(containerId) {
        const container = document.querySelector(`#${containerId}`);
        if (!container) {
            console.warn(`Conteneur introuvable pour l'ID : ${containerId}`);
            return;
        }

        const posts = Array.from(container.querySelectorAll('.blog-post-home'));
        const pagination = document.querySelector('.pagination-blog');

        if (!pagination) {
            console.warn('Pagination introuvable.');
            return;
        }

        const prevButton = pagination.querySelector('.prev-page-blog');
        const nextButton = pagination.querySelector('.next-page-blog');
        const pageIndicator = pagination.querySelector('.page-indicator-blog');

        if (!prevButton || !nextButton || !pageIndicator) {
            console.warn('Boutons ou indicateur de pagination introuvables.');
            return;
        }

        let filteredPosts = posts;
        let totalPosts = posts.length;
        let totalPages = Math.ceil(totalPosts / postsPerPage);
        let currentPage = 1;

        function renderPage(page) {
            const start = (page - 1) * postsPerPage;
            const end = Math.min(start + postsPerPage, totalPosts);

            filteredPosts.forEach((post, index) => {
                post.style.display = index >= start && index < end ? 'block' : 'none';
            });

            updatePaginationControls(page);
            slowScrollToTopBlog(1000); // Défilement fluide vers le haut
        }

        function updatePaginationControls(page) {
            totalPages = Math.ceil(totalPosts / postsPerPage);

            if (totalPosts <= postsPerPage) {
                pagination.style.display = 'none';
                return;
            } else {
                pagination.style.display = 'flex'; // Affiche la pagination si nécessaire
            }

            prevButton.disabled = page === 1;
            nextButton.disabled = page === totalPages;

            let pageLinks = '';
            for (let i = 1; i <= totalPages; i++) {
                pageLinks += i === page
                    ? `<span class="current-page">${i}</span>`
                    : `<a href="#" class="blog-page-link" data-page="${i}">${i}</a>`;
                if (i < totalPages) pageLinks += ' | ';
            }
            pageIndicator.innerHTML = pageLinks;
        }

        prevButton.addEventListener('click', () => {
            if (currentPage > 1) {
                currentPage--;
                renderPage(currentPage);
            }
        });

        nextButton.addEventListener('click', () => {
            if (currentPage < totalPages) {
                currentPage++;
                renderPage(currentPage);
            }
        });

        pageIndicator.addEventListener('click', (event) => {
            const target = event.target;

            // Empêcher les conflits avec d'autres liens
            if (target.tagName === 'A' && target.classList.contains('blog-page-link')) {
                event.preventDefault();
                currentPage = parseInt(target.dataset.page, 10);
                renderPage(currentPage);
            }
        });

        // Gestion des clics sur les catégories
        const categoryLinks = document.querySelectorAll('[data-category-slug]');
        categoryLinks.forEach(link => {
            link.addEventListener('click', function (event) {
                event.preventDefault();

                const selectedCategory = this.getAttribute('data-category-slug');
                filteredPosts = posts.filter(post => post.getAttribute('data-category-slug') === selectedCategory);
                totalPosts = filteredPosts.length;

                if (totalPosts === 0) {
                    console.warn('Aucun article trouvé pour cette catégorie.');
                }

                currentPage = 1; // Réinitialiser à la première page
                renderPage(currentPage);
            });
        });

        // Filtrage initial basé sur l'URL
        const urlPath = window.location.pathname;
        const urlSlug = urlPath.split('/').pop();

        if (urlSlug && urlSlug !== 'blog') {
            filteredPosts = posts.filter(post =>
                post.getAttribute('data-category-slug') === urlSlug
            );
            totalPosts = filteredPosts.length;
            currentPage = 1;
        }

        renderPage(currentPage); // Affiche la première page au chargement
    }

    initializePagination('blog-posts-container');

    // Empêcher les interférences sur les liens "Lire l'article"
    const articleLinks = document.querySelectorAll('.read-article-link');
    articleLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.stopPropagation(); // Arrête la propagation pour éviter d'atteindre d'autres gestionnaires d'événements
        });
    });
});
