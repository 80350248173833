document.addEventListener('DOMContentLoaded', function () {
    const subCategories = document.querySelectorAll('.subcategory');
    const productDisplays = document.querySelectorAll('.subcategory-shop-products-display');
    const subcategorySection = document.getElementById('subcategory-section');
    const subcategoryContainer = document.getElementById('subcategory-container');
    const subcategoryShop = document.querySelector('.subcategory-shop');

    // Vérifier si les éléments nécessaires existent avant d'exécuter le script
    if (!subcategoryContainer || !subcategoryShop || !subcategorySection) {
        return; // Stoppe l'exécution du script si les éléments ne sont pas trouvés
    }

    let subcategoriesLoaded = false;
    let loadedSubcategories = {}; // Gérer les sous-catégories déjà chargées
    let autoScrollInterval;

    // Fonction pour charger les sous-catégories
    function loadSubcategories() {
        if (subcategoriesLoaded) return;
        subcategoriesLoaded = true;

        const subcategoriesData = JSON.parse(subcategorySection.getAttribute('data-subcategories'));

        subcategoriesData.forEach((subCategory) => {
            subcategoryContainer.appendChild(createSubcategoryElement(subCategory));
        });

        // Duplication des sous-catégories pour permettre un défilement infini
        subcategoriesData.forEach((subCategory) => {
            subcategoryContainer.appendChild(createSubcategoryElement(subCategory).cloneNode(true));
        });
    }

    // Créer un élément pour une sous-catégorie
    function createSubcategoryElement(subCategory) {
        const subcategoryElement = document.createElement('div');
        subcategoryElement.classList.add('subcategory');
        subcategoryElement.setAttribute('data-filter-id', subCategory.id);
        subcategoryElement.innerHTML = `
            <a href="#">
                <h2 class="title-subcategory-shop">${subCategory.name}</h2>
            </a>
        `;
        return subcategoryElement;
    }

    // Démarrer le défilement automatique
    function startAutoScroll() {
        clearInterval(autoScrollInterval);
        autoScrollInterval = setInterval(() => {
            const scrollAmount = 1;
            subcategoryShop.scrollLeft += scrollAmount;

            if (subcategoryShop.scrollLeft >= subcategoryContainer.scrollWidth / 2) {
                subcategoryShop.scrollLeft = 0;
            }
        }, 17);
    }

    // Arrêter le défilement automatique
    function stopAutoScroll() {
        clearInterval(autoScrollInterval);
    }

    // Afficher les produits liés à une sous-catégorie
    function showProducts(subCategoryId) {
        productDisplays.forEach(display => {
            display.style.display = 'none';
        });

        const selectedDisplay = document.getElementById(`shop-products-subcategory-display-${subCategoryId}`);
        if (selectedDisplay) {
            selectedDisplay.style.display = 'block';
            loadProducts(selectedDisplay, subCategoryId);
        }
    }

    // Charger les produits de la sous-catégorie
    function loadProducts(displayElement, subCategoryId) {
        if (loadedSubcategories[subCategoryId]) return;
        loadedSubcategories[subCategoryId] = true;

        const products = displayElement.querySelectorAll('.shop-product-display');
        products.forEach((product) => {
            product.classList.add('fade-in');
        });
    }

    // Gérer les clics sur les sous-catégories
    subcategoryContainer.addEventListener('click', function (event) {
        const target = event.target.closest('.subcategory');
        if (target) {
            event.preventDefault(); // Empêche la redirection
            const subCategoryId = target.getAttribute('data-filter-id');
            showProducts(subCategoryId);
        }
    });

    // Ajouter les événements pour arrêter/reprendre le défilement
    subcategoryShop.addEventListener('mouseenter', stopAutoScroll); // Arrêter le défilement au survol
    subcategoryShop.addEventListener('mouseleave', startAutoScroll); // Reprendre le défilement en quittant

    // Charger et démarrer
    loadSubcategories();
    startAutoScroll();
});
