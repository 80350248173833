document.addEventListener('DOMContentLoaded', function() {
    const subCategories = document.querySelectorAll('.subcategory');
    const productDisplays = document.querySelectorAll('.subcategory-shop-products-display');
    const buttonSection = document.querySelector('.total-button-product-shop-product-display'); // Bouton "Voir tous nos produits"
    let loadedSubcategories = {}; // Object pour suivre les sous-catégories déjà chargées
    let buttonLoaded = false; // Indicateur pour le bouton

    // Fonction pour charger les produits avec l'effet de flou progressif
    function loadProducts(displayElement, subCategoryId) {
        // Vérifie si les produits de cette sous-catégorie ont déjà été chargés
        if (loadedSubcategories[subCategoryId]) return;

        loadedSubcategories[subCategoryId] = true; // Marque la sous-catégorie comme chargée

        const products = displayElement.querySelectorAll('.shop-product-display');
        products.forEach((product) => {
            product.classList.add('fade-in'); // Ajout de la classe d'animation
        });
    }

    // Fonction pour charger le bouton avec l'effet de flou progressif
    function loadButton() {
        if (buttonLoaded) return; // Vérifie si le bouton a déjà été chargé
        buttonLoaded = true; // Marque le bouton comme chargé
        buttonSection.classList.add('fade-in'); // Ajoute l'animation
    }

    // Fonction pour initialiser la pagination des produits
    function initializePagination(displayElement) {
        const products = displayElement.querySelectorAll('.shop-product-display');

        const itemsPerPage = window.innerWidth < 570 ? 1 : window.innerWidth < 651 ? 2 : window.innerWidth < 950 ? 4 : 8;
        const totalPages = Math.ceil(products.length / itemsPerPage);
        let currentPage = 0;

        function showPage(page) {
            products.forEach((product, index) => {
                product.style.display = (index >= page * itemsPerPage && index < (page + 1) * itemsPerPage) ? 'block' : 'none';
            });
        }

        function updateChevrons() {
            const previousChevron = document.getElementById('previous-chevron');
            const nextChevron = document.getElementById('next-chevron');

            if (previousChevron && nextChevron) {
                if (currentPage > 0) {
                    previousChevron.classList.remove('disabled');
                } else {
                    previousChevron.classList.add('disabled');
                }

                if (currentPage < totalPages - 1) {
                    nextChevron.classList.remove('disabled');
                } else {
                    nextChevron.classList.add('disabled');
                }
            }
        }

        const previousChevron = document.getElementById('previous-chevron');
        const nextChevron = document.getElementById('next-chevron');

        if (previousChevron && nextChevron) {
            previousChevron.addEventListener('click', function() {
                if (currentPage > 0) {
                    currentPage--;
                    showPage(currentPage);
                    updateChevrons();
                }
            });

            nextChevron.addEventListener('click', function() {
                if (currentPage < totalPages - 1) {
                    currentPage++;
                    showPage(currentPage);
                    updateChevrons();
                }
            });
        }

        showPage(currentPage);
        updateChevrons();
    }

    // Fonction pour observer la section des produits
    function createProductObserver(displayElement, subCategoryId) {
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadProducts(displayElement, subCategoryId); // Charge les produits avec l'effet de flou une seule fois
                    observer.disconnect(); // Déconnecte l'observer après le chargement
                }
            });
        });

        observer.observe(displayElement); // Observer la section des produits
    }

    // Fonction pour observer le bouton
    function createButtonObserver() {
        if (!buttonSection) return; // Vérifie si l'élément existe avant d'essayer de l'observer
    
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadButton(); // Charge le bouton avec l'effet de flou
                    observer.disconnect(); // Déconnecte l'observer après le chargement
                }
            });
        });
    
        observer.observe(buttonSection); // Observer la section du bouton
    }    

    // Gestion du changement de sous-catégorie
    subCategories.forEach(subCategory => {
        subCategory.addEventListener('click', function() {
            const subCategoryId = this.getAttribute('data-filter-id');

            productDisplays.forEach(display => {
                display.style.display = 'none';
            });

            const selectedDisplay = document.getElementById(`shop-products-subcategory-display-${subCategoryId}`);
            if (selectedDisplay) {
                selectedDisplay.style.display = 'block';
                initializePagination(selectedDisplay);
                createProductObserver(selectedDisplay, subCategoryId); // Applique l'observer pour charger les produits de la sous-catégorie
            }
        });
    });

    // Initialisation pour la première sous-catégorie affichée
    const initialActiveDisplay = document.querySelector('.subcategory-shop-products-display:not([style*="display: none"])');
    if (initialActiveDisplay) {
        const initialSubCategoryId = initialActiveDisplay.getAttribute('id').split('-').pop();
        initializePagination(initialActiveDisplay);
        createProductObserver(initialActiveDisplay, initialSubCategoryId); // Applique l'observer pour la première sous-catégorie visible
    }

    // Initialisation de l'observer pour le bouton
    createButtonObserver(); // Observer pour le bouton "Voir tous nos produits"

    // Gestion de la redimension de la fenêtre
    window.addEventListener('resize', function() {
        const activeDisplay = document.querySelector('.subcategory-shop-products-display:not([style*="display: none"])');
        if (activeDisplay) {
            initializePagination(activeDisplay);
        }
    });
});
