document.addEventListener('DOMContentLoaded', function() {
    const titleSection = document.querySelector('.title-and-slogan-shop-product');
    const blogPosts = document.querySelectorAll('.blog-post-display-home');
    let titleLoaded = false;
    let blogsLoaded = false;

    // Fonction pour charger le titre avec l'effet de flou
    function loadTitle() {
        if (titleLoaded) return; // Évite de charger plusieurs fois
        titleLoaded = true;

        titleSection.classList.add('fade-in'); // Ajout de la classe d'animation au titre et slogan
    }

    

    // Fonction pour charger les articles de blog avec l'effet de flou progressif
    function loadBlogPosts() {
        if (blogsLoaded) return; // Évite de charger les articles plusieurs fois
        blogsLoaded = true;

        blogPosts.forEach((post, index) => {
            post.classList.add('fade-in'); // Ajout de la classe d'animation
            post.style.animationDelay = `${index * 0.3}s`; // Délai progressif pour chaque article
        });
    }

    // Fonction pour observer la section du titre
    function createTitleObserver() {

        if (!titleSection) return;
        
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadTitle(); // Charge le titre et le slogan quand ils sont visibles
                    observer.disconnect(); // Déconnecte l'observer après le chargement
                }
            });
        });

        observer.observe(titleSection); // Observer la section du titre et du slogan
    }

    // Fonction pour observer les articles de blog
    function createBlogObserver() {

        if (blogPosts.length === 0) return;

        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadBlogPosts(); // Charge les articles de blog quand ils sont visibles
                    observer.disconnect(); // Déconnecte l'observer après le chargement
                }
            });
        });

        if (blogPosts.length > 0) {
            observer.observe(blogPosts[0]); // Observer la section des articles de blog
        }
    }

    // Initialisation des observers pour le titre et les articles de blog
    createTitleObserver(); // Observer le titre et le slogan
    createBlogObserver(); // Observer les articles de blog
});
