document.addEventListener('DOMContentLoaded', function () {
    const productsPerPage = 12; // Nombre de produits par page
    const maxDisplayedPages = 5; // Nombre de pages maximum à afficher dans la pagination

    // Fonction pour le scroll lent vers le haut
    function slowScrollToTopShop(duration) {
        const startPosition = window.scrollY;
        const startTime = performance.now();

        const animateScroll = (currentTime) => {
            const elapsedTime = currentTime - startTime;
            const progress = Math.min(elapsedTime / duration, 1);
            const easeProgress = 1 - Math.pow(1 - progress, 3);

            window.scrollTo(0, startPosition * (1 - easeProgress));

            if (elapsedTime < duration) {
                requestAnimationFrame(animateScroll);
            }
        };

        const stopScroll = () => {
            window.removeEventListener('wheel', stopScroll);
        };

        window.addEventListener('wheel', stopScroll);
        requestAnimationFrame(animateScroll);
    }

    function handlePagination(containerId) {
        const container = document.querySelector(`#${containerId}`);

        // Vérification si le conteneur existe
        if (!container) return;

        const products = container.querySelectorAll('.productshop-element-list');
        const totalProducts = products.length;
        const totalPages = Math.ceil(totalProducts / productsPerPage);
        let currentPage = 1;

        // Fonction pour afficher une page donnée
        function showPage(page) {
            const start = (page - 1) * productsPerPage;
            const end = Math.min(start + productsPerPage, totalProducts); // Limite pour éviter d'afficher trop d'éléments

            products.forEach((product, index) => {
                if (index >= start && index < end) {
                    product.style.display = 'block';  // Affiche les produits de la page actuelle
                } else {
                    product.style.display = 'none';   // Cache les produits hors plage
                }
            });

            updatePaginationControls(page, totalPages);
            slowScrollToTopShop(1000); // Scroll lent vers le haut avec une durée de 500ms
        }

        // Fonction pour mettre à jour les contrôles de pagination
        function updatePaginationControls(page, totalPages) {
            const pagination = container.querySelector('.pagination');
            if (!pagination) return;

            const pageIndicator = pagination.querySelector('.page-indicator');
            const prevButton = pagination.querySelector('.prev-page');
            const nextButton = pagination.querySelector('.next-page');

            // Si une seule page, cacher la pagination
            if (totalPages <= 1) {
                pagination.style.display = 'none';
                return;
            }

            // Détermination des pages à afficher (maximum 5)
            let startPage = Math.max(1, page - Math.floor(maxDisplayedPages / 2));
            let endPage = Math.min(totalPages, startPage + maxDisplayedPages - 1);

            // Ajuster le début si la dernière page est atteinte
            if (endPage - startPage < maxDisplayedPages - 1) {
                startPage = Math.max(1, endPage - maxDisplayedPages + 1);
            }

            // Affichage des numéros de page sous forme de liens
            let pageLinks = '';
            for (let i = startPage; i <= endPage; i++) {
                if (i === page) {
                    pageLinks += `<span class="current-page">${i}</span>`;
                } else {
                    pageLinks += `<a href="#" class="page-link-shop" data-page="${i}">${i}</a>`;
                }
                if (i < endPage) {
                    pageLinks += ' | '; // Séparation entre les numéros de page
                }
            }
            pageIndicator.innerHTML = pageLinks;

            // Désactiver le bouton "Précédent" si on est sur la première page
            prevButton.disabled = page === 1;
            // Désactiver le bouton "Suivant" si on est sur la dernière page
            nextButton.disabled = page === totalPages;
        }

        // Gestion des clics sur les boutons de navigation
        container.querySelector('.prev-page').addEventListener('click', function () {
            if (currentPage > 1) {
                currentPage--;
                showPage(currentPage);
            }
        });

        container.querySelector('.next-page').addEventListener('click', function () {
            if (currentPage < totalPages) {
                currentPage++;
                showPage(currentPage);
            }
        });

        // Gestion des clics sur les liens de page
        container.addEventListener('click', function (e) {
            if (e.target.classList.contains('page-link-shop')) {
                e.preventDefault();
                const page = parseInt(e.target.dataset.page);
                currentPage = page;
                showPage(currentPage);
            }
        });

        // Afficher la première page par défaut
        showPage(currentPage);
    }

    // Pagination pour chaque sous-catégorie
    const subCategoryContainers = document.querySelectorAll('.subcategory-shop-products-display');
    subCategoryContainers.forEach(container => {
        const subCategoryId = container.id.replace('productshop-id-', '');
        handlePagination(`productshop-id-${subCategoryId}`);
    });

    // Pagination pour tous les produits
    handlePagination('productshop-all-products');

    // Pagination pour la section "Sur Devis"
    handlePagination('productshop-sur-devis');

    // Pagination pour la section "Maintenance"
    handlePagination('productshop-sur-maintenance');
});
