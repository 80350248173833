// Lorsque le document est complètement chargé, exécuter la fonction suivante
// When the document is fully loaded, execute the following function
document.addEventListener('DOMContentLoaded', function() {
    const roleField = document.querySelector('.form-role');
    const companyNameGroup = document.querySelector('.form-company-name-group');

    // Fonction pour afficher ou masquer le champ "Nom de la société" en fonction du rôle sélectionné
    // Function to show or hide the "Company Name" field based on the selected role
    function toggleCompanyName() {
        if (roleField && companyNameGroup) {
            if (roleField.value === 'Professionnel') {
                // Si le rôle sélectionné est "Professionnel", afficher le champ "Nom de la société"
                // If the selected role is "Professionnel", show the "Company Name" field
                companyNameGroup.classList.add('visible');
                companyNameGroup.classList.remove('hidden');
            } else {
                // Sinon, masquer le champ "Nom de la société"
                // Otherwise, hide the "Company Name" field
                companyNameGroup.classList.add('hidden');
                companyNameGroup.classList.remove('visible');
            }
        }
    }

    // Vérification initiale lors du chargement de la page
    // Initial check when the page loads
    if (roleField && companyNameGroup) {
        toggleCompanyName();

        // Ajouter un écouteur d'événement pour détecter les changements dans le champ de rôle
        // Add an event listener to detect changes in the role field
        roleField.addEventListener('change', toggleCompanyName);
    }

    // Code pour afficher et masquer le message de succès
    // Code to display and hide the success message
    const flashMessage = document.querySelector('.alert-success');

    if (flashMessage) {
        setTimeout(() => {
            // Transition pour faire disparaître progressivement le message
            // Transition to gradually fade out the message
            flashMessage.style.transition = 'opacity 1s ease';
            flashMessage.style.opacity = '0';
            setTimeout(() => {
                // Retirer le message après la transition
                // Remove the message after the transition
                flashMessage.remove();
            }, 1000); // Attendre que la transition se termine avant de retirer l'élément
                      // Wait for the fade-out transition to complete before removing the element
        }, 5000); // Afficher le message pendant 5 secondes
                  // Display the message for 5 seconds
    }
});
