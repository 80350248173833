document.addEventListener('DOMContentLoaded', function() {
    function initCarousel() {
        let items = document.querySelectorAll('.carousel-item');
        let currentItem = 0;
        const totalItems = items.length;
        const changeInterval = 5000;

        // Lazy load des images après le chargement de la page
        function lazyLoadImages() {
            const lazyImages = document.querySelectorAll('.img-header.lazy');
            lazyImages.forEach((img) => {
                img.src = img.getAttribute('data-src');
                img.classList.remove('lazy');
            });
        }

        if (items.length > 0) {
            const rightChevron = document.querySelector('.chevron-right');
            const leftChevron = document.querySelector('.chevron-left');

            function showCurrentItem() {
                items.forEach((item, index) => {
                    item.classList.remove('active');
                    if (index === currentItem) {
                        item.classList.add('active');
                    }
                });
            }

            function nextItem() {
                currentItem = (currentItem + 1) % totalItems;
                showCurrentItem();
            }

            function prevItem() {
                currentItem = (currentItem - 1 + totalItems) % totalItems;
                showCurrentItem();
            }

            if (rightChevron && leftChevron) {
                rightChevron.addEventListener('click', nextItem);
                leftChevron.addEventListener('click', prevItem);
            }

            showCurrentItem();
            setInterval(nextItem, changeInterval);

            // Charger les images restantes après l'initialisation du carrousel
            lazyLoadImages();
        }
    }

    // Initialisation du carrousel une fois que la page entière est chargée
    window.addEventListener('load', initCarousel);
});
