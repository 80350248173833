document.addEventListener('DOMContentLoaded', function() {
    const productDisplays = document.querySelectorAll('.filtered-product-home-display-list');

    productDisplays.forEach((display) => {
        const scrollSpeed = parseInt(display.getAttribute('data-scroll-speed')) || 1; // Vitesse de défilement
        const waitTime = 3000;
        let scrollDirection = 1;
        let isPaused = false;

        function startScrolling() {
            if (isPaused) return;

            display.scrollTop += scrollDirection * scrollSpeed;

            // Bas de la liste
            if (scrollDirection === 1 && display.scrollTop + display.clientHeight >= display.scrollHeight) {
                isPaused = true;
                scrollDirection = -1;
                setTimeout(() => {
                    isPaused = false;
                    requestAnimationFrame(startScrolling);
                }, waitTime);
            }
            // Haut de la liste
            else if (scrollDirection === -1 && display.scrollTop <= 0) {
                isPaused = true;
                scrollDirection = 1;
                setTimeout(() => {
                    isPaused = false;
                    requestAnimationFrame(startScrolling);
                }, waitTime);
            } else {
                requestAnimationFrame(startScrolling);
            }
        }

        // Initialiser le défilement pour chaque liste
        requestAnimationFrame(startScrolling);

        // Gérer le survol pour arrêter et reprendre le défilement
        display.addEventListener('mouseenter', () => {
            isPaused = true;
        });
        display.addEventListener('mouseleave', () => {
            isPaused = false;
            requestAnimationFrame(startScrolling);
        });
    });
});
