document.addEventListener('DOMContentLoaded', function() {
    const productSection = document.querySelector('#product-scroll-section'); // Cible uniquement cette section
    if (!productSection) return; // Arrête si l'élément n'existe pas

    const productContainer = document.createElement('div');
    const scrollSpeed = 30; // Ajustez la vitesse de défilement ici
    let scrollInterval;

    // Configuration du style du conteneur pour le défilement horizontal
    productSection.style.overflow = 'hidden';
    productContainer.style.display = 'flex';

    // Récupère les produits depuis l'attribut data-products en tant que JSON
    const productData = JSON.parse(productSection.getAttribute('data-products'));

    // Fonction pour créer et ajouter des éléments de produit
    function addProducts(products) {
        products.forEach(product => {
            const productElement = createProductElement(product);
            productContainer.appendChild(productElement);
        });
    }

    // Crée dynamiquement les éléments HTML pour chaque produit
    addProducts(productData);
    addProducts(productData); // Ajoute une duplication pour un défilement infini

    // Ajoute le conteneur de produits à la section principale
    productSection.appendChild(productContainer);

    // Fonction pour créer un élément produit
    function createProductElement(product) {
        const productElement = document.createElement('div');
        productElement.classList.add('product-display');

        productElement.innerHTML = `
            <div class="product-display-container">
                <picture>
                    <source srcset="${product.img}" type="image/webp">
                    <img class="img-display" src="${product.img}" alt="${product.name}" loading="lazy">
                </picture>
                <div class="overlay-display"></div>
                <div class="product-display-overlay1" style="opacity: 0; transition: opacity 0.5s;">
                    <h2 class="title-product-display1">${product.name}</h2>
                    <p class="slogan-product-display1">${product.slogan}</p>
                    <a href="${product.url}" class="button-product-display1">Découvrir</a>
                </div>
            </div>
        `;

        const imgElement = productElement.querySelector('.img-display');
        const overlay = productElement.querySelector('.product-display-overlay1');

        imgElement.addEventListener('load', () => {
            overlay.style.opacity = 1;
        });

        if (imgElement.complete) {
            overlay.style.opacity = 1;
        }

        return productElement;
    }

    // Fonction pour faire défiler les produits vers la gauche
    function scrollProducts() {
        productSection.scrollLeft += 1;

        if (productSection.scrollLeft >= productContainer.scrollWidth / 2) {
            productSection.scrollLeft = 0;
        }
    }

    // Fonction pour démarrer le défilement
    function startScrolling() {
        scrollInterval = setInterval(scrollProducts, scrollSpeed);
    }

    // Fonction pour arrêter le défilement
    function stopScrolling() {
        clearInterval(scrollInterval);
    }

    // Démarre le défilement automatique
    startScrolling();

    // Arrête le défilement au survol et le reprend à la sortie
    productSection.addEventListener('mouseenter', stopScrolling);
    productSection.addEventListener('mouseleave', startScrolling);
});
