import { Controller } from "@hotwired/stimulus";
import { slowScrollToTop } from '../styles/libre-acces-js/general-js/_scroll-utils.js';

export default class extends Controller {
    static targets = ["subMenu", "productDisplay", "devisProducts", "maintenanceProducts"];
    animationFrameId = null;

    connect() {
        this.productDisplays = this.productDisplayTargets;
        this.devisProductsDisplay = document.getElementById("productshop-sur-devis");
        this.maintenanceProductsDisplay = document.getElementById("productshop-sur-maintenance");
        this.isProductPage = window.location.pathname.includes('/fiche-produit/'); // Vérifie si on est sur une page fiche produit

        if (!this.isProductPage) {
            const urlPath = window.location.pathname;
            const subCategorySlug = urlPath.split('/').pop();

            if (subCategorySlug && subCategorySlug !== 'boutique') {
                // Applique le filtre pour les produits de la sous-catégorie
                this.applySlugFilter(subCategorySlug);
            }
        }

        this.sidebarShop = this.element.querySelector(".sidebar-shop");
        this.menuBurgerShopButton = this.element.querySelector(".menu-shop-burger");
        this.menuBurgerSheetButton = this.element.querySelector(".menu-sheet-burger");
        this.closedMenuBurger = this.element.querySelector(".closed-menu-burger-shop");

        // Cache le menu au chargement uniquement si on est en mode mobile
        this.handleResize();

        // Attacher les événements aux boutons
        if (this.menuBurgerShopButton) {
            this.menuBurgerShopButton.addEventListener("click", this.toggleSidebar.bind(this));
        }
        if (this.menuBurgerSheetButton) {
            this.menuBurgerSheetButton.addEventListener("click", this.toggleSidebar.bind(this));
        }
        if (this.closedMenuBurger) {
            this.closedMenuBurger.addEventListener("click", this.toggleSidebar.bind(this));
        }

        // Écouteur pour redimensionnement de l'écran
        window.addEventListener("resize", this.handleResize.bind(this));
    }

    disconnect() {
        window.removeEventListener("resize", this.handleResize.bind(this));
    }

    toggleAndShowProducts(event) {
        const chevron = event.currentTarget;
        const subMenu = chevron.closest('.product-title').nextElementSibling;
        const subCategoryId = chevron.getAttribute('data-filter-id');
        const subCategorySlug = chevron.getAttribute('data-category-slug');
        const subCategoryDescription = chevron.getAttribute('data-category-description');
        const subCategoryKeywords = chevron.getAttribute('data-category-keywords');

        this.subMenuTargets.forEach(menu => {
            if (menu !== subMenu) {
                menu.style.display = 'none';
            }
        });

        if (subMenu) {
            const isCurrentlyDisplayed = subMenu.style.display === 'block';
            subMenu.style.display = isCurrentlyDisplayed ? 'none' : 'block';
        }

        this.hideAllProductDisplays();

        this.productDisplays.forEach(display => {
            const shouldDisplay = display.id === `productshop-id-${subCategoryId}`;
            if (shouldDisplay) {
                display.style.display = 'block';
            }
        });

        // Mettre à jour l'URL et les balises meta uniquement si ce n'est pas une fiche produit
        if (!this.isProductPage) {
            // Mettre à jour dynamiquement l'URL avec le slug
            this.updateUrl(subCategorySlug);

            // Mettre à jour les balises <meta> dynamiquement
            this.updateMetaTags(subCategoryDescription, subCategoryKeywords);

            // Faire défiler vers le haut
            slowScrollToTop(1000); // 1 seconde
        }
    }

    applySlugFilter(slug) {
        let hasMatch = false;

        // Parcourt tous les produits pour afficher ceux correspondant au slug
        this.productDisplays.forEach(product => {
            const isMatch = product.getAttribute('data-filter-slug') === slug || product.getAttribute('data-category-slug') === slug;
            product.style.display = isMatch ? 'block' : 'none';
            hasMatch ||= isMatch;
        });

        // Cacher les produits "sur devis" ou "maintenance" si un filtre est appliqué
        if (this.devisProductsDisplay) {
            this.devisProductsDisplay.style.display = hasMatch ? 'none' : 'block';
        }
        if (this.maintenanceProductsDisplay) {
            this.maintenanceProductsDisplay.style.display = hasMatch ? 'none' : 'block';
        }

        // Met à jour l'URL dynamiquement
        this.updateUrl(slug);

        // Récupérer les métadonnées à partir du filtre ou de la catégorie correspondante
        const matchingFilter = document.querySelector(`[data-filter-slug="${slug}"], [data-category-slug="${slug}"]`);
        const metaDescription = matchingFilter?.getAttribute('data-meta-description') ||
            "Explorez notre Boutique et découvrez nos produits entièrement dédiés à l'accessibilité.";
        const metaKeywords = matchingFilter?.getAttribute('data-meta-keywords') ||
            "boutique, accessibilité, produits";

        // Mettre à jour les métadonnées dynamiquement
        this.updateMetaTags(metaDescription, metaKeywords);

        // Ouvrir le sous-menu correspondant
        this.subMenuTargets.forEach(menu => {
            const parent = menu.previousElementSibling; // Prend l'élément juste avant le sous-menu
            const parentSlug = parent?.getAttribute('data-category-slug');
            if (parentSlug === slug) {
                menu.style.display = 'block';
            } else {
                menu.style.display = 'none';
            }
        });
    }

    updateMetaTags(description, keywords) {
        let metaDescription = document.querySelector("meta[name='description']");
        let metaKeywords = document.querySelector("meta[name='keywords']");

        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            document.head.appendChild(metaDescription);
        }
        if (!metaKeywords) {
            metaKeywords = document.createElement('meta');
            metaKeywords.setAttribute('name', 'keywords');
            document.head.appendChild(metaKeywords);
        }

        metaDescription.setAttribute('content', description || "Explorez notre Boutique et découvrez nos produits entièrement dédiés à l'accessibilité.");
        metaKeywords.setAttribute('content', keywords || "boutique, accessibilité, produits");
    }

    filterProducts(event) {
        const filterElement = event.currentTarget;
        const filterSlug = filterElement.getAttribute('data-filter-slug');
        const metaDescription = filterElement.getAttribute('data-meta-description');
        const metaKeywords = filterElement.getAttribute('data-meta-keywords');

        // Appliquer le filtre par slug
        this.applySlugFilter(filterSlug, metaDescription, metaKeywords);

        // Faire défiler vers le haut de manière fluide
        slowScrollToTop(1000);
    }

    updateUrl(slug) {
        if (!window.location.pathname.includes('/fiche-produit/')) {
            const newUrl = `${window.location.origin}/boutique/${slug}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        }
    }

    hideAllProductDisplays() {
        if (this.devisProductsDisplay) {
            this.devisProductsDisplay.style.display = 'none';
        }
        if (this.maintenanceProductsDisplay) {
            this.maintenanceProductsDisplay.style.display = 'none';
        }
        this.productDisplays.forEach(display => {
            display.style.display = 'none';
        });
    }


    displayAllProducts() {
        this.hideAllProductDisplays(); // Assurez-vous que tout est caché d'abord

        // Récupérer le slug actuel dans l'URL
        const urlPath = window.location.pathname;
        const subCategorySlug = urlPath.split('/').pop();

        // Vérifier si un slug est présent et appliquer le filtre correspondant
        if (subCategorySlug && subCategorySlug !== 'boutique') {
            this.applySlugFilter(subCategorySlug); // Affiche uniquement les produits liés au slug
        } else {
            // Sinon, affiche tous les produits
            const allProductsDisplay = document.getElementById("productshop-all-products");
            if (allProductsDisplay) {
                allProductsDisplay.style.display = 'block';
            }
        }
    }

    showProductsBySubCategory(subCategoryId) {
        this.productDisplays.forEach(display => {
            const shouldDisplay = display.id === `productshop-id-${subCategoryId}`;
            if (shouldDisplay) {
                display.style.display = 'block';  // On montre uniquement les produits de la sous-catégorie
            }
        });
    }

    showDevisProducts() {
        this.hideAllProductDisplays();

        if (this.devisProductsDisplay) {
            this.devisProductsDisplay.style.display = 'block';
        }
    }

    showMaintenanceProducts() {
        this.hideAllProductDisplays();

        if (this.maintenanceProductsDisplay) {
            this.maintenanceProductsDisplay.style.display = 'block';
        }
    }

    toggleSidebar(event) {
        event.preventDefault();

        if (this.sidebarShop.classList.contains("show")) {
            this.sidebarShop.classList.remove("show");
            setTimeout(() => {
                this.sidebarShop.style.display = "none";
            }, 300);
        } else {
            this.sidebarShop.style.display = "block";
            setTimeout(() => {
                this.sidebarShop.classList.add("show");
            }, 10);
        }
    }

    handleResize() {
        const screenWidth = window.innerWidth;

        if (screenWidth >= 866) {
            // Mode Desktop : le menu est toujours visible
            if (this.sidebarShop) {
                this.sidebarShop.style.display = "block";
            }
        } else {
            // Mode Mobile : garder caché si non ouvert
            if (this.sidebarShop && !this.sidebarShop.classList.contains("show")) {
                this.sidebarShop.style.display = "none";
            }
        }
    }

}
