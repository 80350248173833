import { Controller } from "@hotwired/stimulus";
import { slowScrollToTop } from '../styles/libre-acces-js/general-js/_scroll-utils.js';

export default class extends Controller {
    static targets = ["subMenu", "postDisplay"];
    animationFrameId = null;

    connect() {
        this.posts = document.querySelectorAll('.blog-post-home');
        this.isBlogHomePage = document.querySelector('.content-blog-home') !== null;
        this.isBlogPostPage = document.querySelector('.blog-post-page') !== null; // Détermine si on est sur un article

        const urlPath = window.location.pathname;
        const categorySlug = urlPath.split('/').pop();

        if (categorySlug && categorySlug !== 'blog' && this.isBlogHomePage) {
            this.applySlugFilter(categorySlug);
        }
    }

    openSubMenuBySlug(slug) {
        const subMenu = Array.from(this.subMenuTargets).find(menu => {
            const parentCategory = menu.closest('li').querySelector('[data-category-slug]');
            return parentCategory && parentCategory.getAttribute('data-category-slug') === slug;
        });
    
        if (subMenu) {
            subMenu.style.display = 'block'; // Affiche le sous-menu
        }
    }    

    applySlugFilter(slug) {
        // Filtrage initial des articles
        this.posts.forEach(post => {
            const postCategorySlug = post.getAttribute('data-category-slug');
            post.style.display = postCategorySlug === slug ? 'block' : 'none';
        });
    
        // Ouvrir le sous-menu associé
        this.openSubMenuBySlug(slug);
    }    

    handleCategoryClick(event) {
        const categorySlug = event.currentTarget.getAttribute('data-category-slug');
        const categoryDescription = event.currentTarget.getAttribute('data-category-description');
        const categoryKeywords = event.currentTarget.getAttribute('data-category-keywords');

        this.filterPostsByCategory(categorySlug);
        this.toggleSubMenu(event.currentTarget);
        // Met à jour l'URL et les méta seulement si on est sur la page d'accueil du blog, pas sur un article
        if (this.isBlogHomePage) {
            this.updateUrl(categorySlug);
            this.updateMetaTags(categoryDescription, categoryKeywords);
        }

        // Ajoute un défilement en haut de la page lors de la sélection d'une catégorie
        slowScrollToTop(1000); // Durée de 1 seconde pour l'animation
    }

    filterPostsByCategory(slug) {
        this.posts.forEach(post => {
            const postCategorySlug = post.getAttribute('data-category-slug');
            post.style.display = postCategorySlug === slug ? 'block' : 'none';
        });
    }

    updateMetaTags(description, keywords) {
        let metaDescription = document.querySelector("meta[name='description']");
        let metaKeywords = document.querySelector("meta[name='keywords']");

        if (!metaDescription) {
            metaDescription = document.createElement('meta');
            metaDescription.setAttribute('name', 'description');
            document.head.appendChild(metaDescription);
        }
        if (!metaKeywords) {
            metaKeywords = document.createElement('meta');
            metaKeywords.setAttribute('name', 'keywords');
            document.head.appendChild(metaKeywords);
        }

        metaDescription.setAttribute('content', description);
        metaKeywords.setAttribute('content', keywords);
    }

    updateUrl(categorySlug) {
        const newUrl = `${window.location.origin}/blog/${categorySlug}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    }

    toggleSubMenu(target) {
        const parentLi = target.closest('li');
        const subMenu = parentLi.querySelector('.sub-menu-blog');

        if (!subMenu) {
            return;
        }

        this.subMenuTargets.forEach(menu => {
            if (menu !== subMenu) {
                menu.style.display = 'none';
            }
        });

        subMenu.style.display = subMenu.style.display === "block" ? "none" : "block";
    }
}
