document.addEventListener("DOMContentLoaded", function () {
    // Sélectionne tous les éléments avec un chevron
    const dropdownToggles = document.querySelectorAll(".menu-account-burger > a, .menu-contact-burger > a");

    dropdownToggles.forEach(toggle => {
        toggle.addEventListener("click", function (event) {
            event.preventDefault(); // Empêche le lien d'être suivi

            const parent = this.parentElement; // Récupère le parent <li> contenant la classe .menu-account-burger ou .menu-contact-burger
            parent.classList.toggle("active"); // Ajoute/Supprime la classe active
        });
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const burgerIcon = document.querySelector(".bi-list"); // Icône pour ouvrir
    const burgerCloseIcon = document.querySelector(".bi-x-circle"); // Icône pour fermer
    const burgerMenu = document.querySelector(".burger-menu-container"); // Menu burger
    
    // Ouvrir le menu
    burgerIcon.addEventListener("click", function () {
        burgerMenu.classList.add("active");
    });

    // Fermer le menu
    burgerCloseIcon.addEventListener("click", function () {
        burgerMenu.classList.remove("active");
    });

    // Fermer si on clique en dehors du menu
    document.addEventListener("click", function (event) {
        if (!burgerMenu.contains(event.target) && !burgerIcon.contains(event.target)) {
            burgerMenu.classList.remove("active");
        }
    });

    // Fermer le menu si la fenêtre est redimensionnée au-delà de 866px
    window.addEventListener("resize", function () {
        if (window.innerWidth > 866) {
            burgerMenu.classList.remove("active");
        }
    });
});
