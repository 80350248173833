import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "itemTotalHt",
        "itemTotalTtc",
        "quantityInput",
        "totalPriceHt",
        "totalPriceTtc",
    ];

    updateQuantity(event) {
        const input = event.target;
        const productId = input.closest("tr").querySelector("[data-product-id]")
            .dataset.productId;

        // Envoi AJAX pour mettre à jour le panier côté serveur
        fetch("/panier/mettre-a-jour", {
            method: "POST",
            body: new FormData(this.element),
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data); // Affiche l'intégralité des données renvoyées

                if (data.items && data.items[productId]) {
                    // Mise à jour du prix total HT pour le produit correspondant
                    const itemTotalHtElement = this.itemTotalHtTargets.find(
                        (el) => el.closest("td").dataset.productId === productId
                    );
                    if (itemTotalHtElement) {
                        console.log(
                            `HT reçu pour le produit ${productId}: ${data.items[productId].newPriceHt}`
                        );
                        itemTotalHtElement.innerText = `${data.items[
                            productId
                        ].newPriceHt.toFixed(2)} € HT`;
                    }

                    // Mise à jour du prix total TTC pour le produit correspondant
                    const itemTotalTtcElement = this.itemTotalTtcTargets.find(
                        (el) => el.closest("td").dataset.productId === productId
                    );
                    if (itemTotalTtcElement) {
                        console.log(
                            `TTC reçu pour le produit ${productId}: ${data.items[productId].newPriceTtc}`
                        );
                        itemTotalTtcElement.innerText = `${data.items[
                            productId
                        ].newPriceTtc.toFixed(2)} € TTC`;
                    }
                }

                // Mettre à jour le prix total HT du panier
                if (this.hasTotalPriceHtTarget) {
                    this.totalPriceHtTarget.innerText = `${data.newTotalPriceHt.toFixed(
                        2
                    )} € HT`;
                }

                // Mettre à jour le prix total TTC du panier
                if (this.hasTotalPriceTtcTarget) {
                    this.totalPriceTtcTarget.innerText = `${data.newTotalPriceTtc.toFixed(
                        2
                    )} € TTC`;
                }
            })
            .catch((error) => console.error("Erreur:", error));
    }
}
