document.addEventListener('DOMContentLoaded', function() {
    const postsContainer = document.querySelector('.latest-blog-posts');
    let loadedPosts = false;

    // Fonction pour charger tous les articles de blog récents via AJAX
    function loadRecentBlogPosts() {
        if (loadedPosts) return; // Empêcher de charger plusieurs fois
        loadedPosts = true;

        // Effectuer la requête AJAX pour récupérer les articles récents
        fetch('/recent-blog-posts')
            .then(response => response.json())
            .then(posts => {
                // Vérification de la structure des données
                if (!Array.isArray(posts)) {
                    console.error('Les données reçues ne sont pas valides.');
                    return;
                }

                // Afficher tous les articles récents
                posts.forEach(post => {
                    const postElement = document.createElement('div');
                    postElement.classList.add('blog-post', 'fade-in'); // Ajout de la classe d'animation

                    // Créer le contenu de chaque article
                    const publicationDate = new Date(post.blogPostPublicationDate);
                    const day = publicationDate.getDate().toString().padStart(2, '0');
                    const month = publicationDate.toLocaleString('fr', { month: 'long' });

                    postElement.innerHTML = `
                        <div class="image-and-content">
                            <div class="image-container">
                                <img src="${post.blogPostImg}" alt="${post.blogPostTitle}" loading="lazy">
                                <div class="date-overlay">
                                    <span class="day">${day}</span>
                                    <span class="month">${month}</span>
                                </div>
                            </div>
                            <div class="content-container">
                                <h3 class="title-blog-post">${post.blogPostTitle}</h3>
                                <p class="text-blog-post">${post.blogPostShortDescription}</p>
                                <div class="blog-post-content">
                                    ${post.blogPostContent.slice(0, 140)}${post.blogPostContent.length > 140 ? '...' : ''}
                                </div>
                                <div class="read-more">
                                    <a href="${post.blogPostUrl}">Lire la suite</a>
                                </div>
                            </div>
                        </div>
                    `;

                    // Ajouter l'article au conteneur
                    postsContainer.appendChild(postElement);
                });
            })
            .catch(error => {
                console.error('Erreur lors du chargement des articles récents:', error);
            });
    }

    // Fonction pour observer la section des articles récents avec IntersectionObserver
    function createObserver() {
        if (!postsContainer) return;

        const blogSection = document.querySelector('.total-financial-help'); // Vous pouvez changer cette classe si nécessaire
        const observer = new IntersectionObserver(function(entries) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    loadRecentBlogPosts(); // Charge tous les articles récents via AJAX
                    observer.disconnect(); // Déconnecter l'observer après chargement
                }
            });
        });

        observer.observe(blogSection); // Observer la section des articles
    }

    // Démarrer l'observation des articles
    createObserver();
});
