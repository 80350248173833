import { Controller } from "@hotwired/stimulus";
import { loadStripe } from "@stripe/stripe-js";

export default class extends Controller {
  static values = {
    stripePublicKey: String,
    totalPrice: Number,
  };

  connect() {
    // Ajouter le listener passif ici si vous gérez le défilement
    window.addEventListener('scroll', this.handleScroll.bind(this), { passive: true });

    this.initializeStripe();
  }

  async initializeStripe() {
    try {
        console.log('Initializing Stripe...');
        const stripe = await loadStripe(this.stripePublicKeyValue);
        console.log('Stripe initialized:', stripe);

        const amountInCents = Math.round(this.totalPriceValue * 100);
        console.log('Total price in cents:', amountInCents);

        if (isNaN(amountInCents) || amountInCents <= 0) {
            console.error("Total price is not valid.");
            return;
        }

        const response = await fetch("/create-payment-intent", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ amount: amountInCents }),
        });

        console.log('Payment intent response:', response);
        if (!response.ok) {
            throw new Error(`Failed to create PaymentIntent: ${await response.text()}`);
        }

        const { clientSecret } = await response.json();
        console.log('Client secret:', clientSecret);

        const elements = stripe.elements({ clientSecret });
        console.log('Elements initialized:', elements);

        const paymentElement = elements.create("payment");
        paymentElement.mount("#payment-element");

        const expressCheckoutElement = elements.create("expressCheckout");
        expressCheckoutElement.mount("#express-checkout-element");

        expressCheckoutElement.on("click", (event) => {
            const options = {
              emailRequired: true,
              total: {
                  label: 'Total',
                  amount: this.totalPriceValue * 100,
              },
          };
            console.log('Express Checkout clicked, options:', options);
            event.resolve(options);
        });

        expressCheckoutElement.on("confirm", async (event) => {
            const { error } = await stripe.confirmPayment({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: `${window.location.origin}/payment/success`,
                },
            });

            if (error) {
                console.error('Payment confirmation error:', error);
                document.getElementById("error-message").textContent = error.message;
            } else {
                console.log("Redirection vers la return_url");
            }
        });

        this.element
            .querySelector("#payment-form")
            .addEventListener("submit", async (event) => {
                event.preventDefault();
                console.log('Form submitted, processing payment...');

                const { error: submitError } = await elements.submit();

                if (submitError) {
                    console.error('Form submission error:', submitError);
                    document.getElementById("error-message").textContent = submitError.message;
                    return;
                }

                const { error: paymentError } = await stripe.confirmPayment({
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: `${window.location.origin}/payment/success`,
                    },
                });

                if (paymentError) {
                    console.error('Payment error:', paymentError);
                    document.getElementById("error-message").textContent = paymentError.message;
                } else {
                    console.log("Payment successful!");
                }
            });
    } catch (error) {
        console.error("Error during payment initialization:", error);
    }
}

  // Supposons que vous ayez un handleScroll dans votre classe
  handleScroll() {
    console.log('Scroll event detected');
  }
}
