document.addEventListener('DOMContentLoaded', function () {
    // Lazy loading des images dans le footer
    const lazyImages = document.querySelectorAll('.footer-background-img');
    if ('IntersectionObserver' in window) {
        let lazyImageObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    let img = entry.target;
                    img.src = img.dataset.src;
                    img.classList.remove('lazy');
                    lazyImageObserver.unobserve(img);
                }
            });
        });

        lazyImages.forEach(function (image) {
            lazyImageObserver.observe(image);
        });
    } else {
        lazyImages.forEach(function (image) {
            image.src = image.dataset.src;
        });
    }

    // Gestion des sous-catégories
    const categorySection = document.querySelector('.menu-footer-categories');
    if (categorySection) {
        const categoryTitles = categorySection.querySelectorAll('.menu-footer-title');
        const categoryChevrons = categorySection.querySelectorAll('.title-category-footer');
        const subCategoryWrappers = categorySection.querySelectorAll('.menu-footer-subcategories');

        subCategoryWrappers.forEach(wrapper => (wrapper.style.display = 'none'));

        function toggleCategories(titleElement, chevrons, wrappers) {
            const content = titleElement.nextElementSibling;

            // Vérifie si la catégorie cliquée est déjà ouverte
            const isAlreadyOpen = content && content.style.display === 'block';

            // Ferme toutes les sous-catégories
            wrappers.forEach(wrapper => (wrapper.style.display = 'none'));
            chevrons.forEach(chevron => (chevron.style.transform = 'rotate(0deg)'));

            // Si elle n'était pas ouverte, on l'ouvre
            if (!isAlreadyOpen && content) {
                content.style.display = 'block';
                const chevron = titleElement.querySelector('i');
                if (chevron) chevron.style.transform = 'rotate(180deg)';
            }
        }

        categoryTitles.forEach(title =>
            title.addEventListener('click', function () {
                toggleCategories(this, categoryChevrons, subCategoryWrappers);
            })
        );

        categoryChevrons.forEach(chevron =>
            chevron.addEventListener('click', function (event) {
                event.stopPropagation();
                toggleCategories(this.parentElement, categoryChevrons, subCategoryWrappers);
            })
        );
    }

    // Gestion des filtres
    const filterSection = document.querySelector('.menu-footer-filters');
    if (filterSection) {
        const filterTitle = filterSection.querySelector('.menu-footer-title');
        const filterChevron = filterSection.querySelector('.title-filter-footer');
        const filterContent = filterSection.querySelector('.menu-footer-subfilters');

        filterContent.style.display = 'none';

        function toggleFilters() {
            if (filterContent.style.display === 'none') {
                filterContent.style.display = 'block';
                filterChevron.style.transform = 'rotate(180deg)';
            } else {
                filterContent.style.display = 'none';
                filterChevron.style.transform = 'rotate(0deg)';
            }
        }

        filterTitle.addEventListener('click', toggleFilters);
        filterChevron.addEventListener('click', function (event) {
            event.stopPropagation();
            toggleFilters();
        });
    }
});
