export function slowScrollToTop(duration, animationFrameId = null) {
    if (window.scrollY === 0) return; // Rien à faire si déjà en haut

    const startPosition = window.scrollY;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easeProgress = 1 - Math.pow(1 - progress, 3);

        const nextPosition = startPosition * (1 - easeProgress);
        window.scrollTo(0, nextPosition);

        if (elapsedTime < duration) {
            animationFrameId = requestAnimationFrame(animateScroll);
        } else {
            // Forcer la position finale à 0 pour éviter tout décalage
            window.scrollTo(0, 0);
        }
    };

    const stopScroll = () => {
        if (animationFrameId) {
            cancelAnimationFrame(animationFrameId);
            animationFrameId = null;
            window.removeEventListener('wheel', stopScroll);
        }
    };

    window.addEventListener('wheel', stopScroll);
    requestAnimationFrame(animateScroll);
}
