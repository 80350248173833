// Lorsque le document est complètement chargé, exécuter la fonction suivante
// When the document is fully loaded, execute the following function
document.addEventListener('DOMContentLoaded', function() {
    // Sélectionne le champ de rôle et les champs associés à la société
    // Select the role field and the company-related fields
    const roleField = document.querySelector('.form-role-registration');
    const companyFields = document.querySelectorAll('.form-company');

    if (roleField && companyFields.length > 0) {
        // Fonction pour afficher ou masquer les champs de la société en fonction du rôle sélectionné
        // Function to show or hide company fields based on the selected role
        function toggleCompanyFields() {
            companyFields.forEach(field => {
                const formGroup = field.closest('.form-group-registration');
                if (formGroup) {
                    if (roleField.value === 'ROLE_PRO') {
                        // Affiche les champs de la société et les rend obligatoires si le rôle est "Professionnel"
                        // Show the company fields and make them required if the role is "Professional"
                        formGroup.classList.add('visible-registration');
                        formGroup.classList.remove('hidden-registration');
                        field.required = true;
                    } else {
                        // Masque les champs de la société et les rend facultatifs si le rôle n'est pas "Professionnel"
                        // Hide the company fields and make them optional if the role is not "Professional"
                        formGroup.classList.add('hidden-registration');
                        formGroup.classList.remove('visible-registration');
                        field.required = false;
                    }
                }
            });
        }

        // Vérification initiale à l'affichage de la page
        // Initial check when the page loads
        toggleCompanyFields();

        // Ajoute un écouteur d'événement pour surveiller les changements du champ de rôle
        // Add an event listener to monitor changes in the role field
        roleField.addEventListener('change', toggleCompanyFields);
    }
});


