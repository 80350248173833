document.addEventListener('DOMContentLoaded', function () {
    const filtersSection = document.getElementById('filters-section');
    const filterContainer = document.getElementById('filter-container');
    const filtersWrapper = document.querySelector('.filters');

    // Vérifier si les éléments nécessaires existent avant d'exécuter le script
    if (!filtersSection || !filterContainer || !filtersWrapper) {
        return; // Stoppe l'exécution du script si un élément est manquant
    }

    let filtersLoaded = false;
    let autoScrollInterval;
    let userInteractionTimeout;

    // Fonction pour charger les filtres
    function loadFilters() {
        if (filtersLoaded) return;
        filtersLoaded = true;

        // Récupère les filtres depuis l'attribut data-filters en tant que JSON
        const filtersData = JSON.parse(filtersSection.getAttribute('data-filters'));

        // Crée dynamiquement les éléments HTML pour chaque filtre et les duplique pour le défilement infini
        filtersData.forEach((filter) => {
            filterContainer.appendChild(createFilterElement(filter));
        });

        // Duplication des filtres pour permettre un défilement infini
        filtersData.forEach((filter) => {
            filterContainer.appendChild(createFilterElement(filter).cloneNode(true));
        });
    }

    // Fonction pour créer un élément filtre
    function createFilterElement(filter) {
        const filterElement = document.createElement('div');
        filterElement.classList.add('filter-display', 'fade-in');
        filterElement.innerHTML = `
            <a href="${filter.url}">
                <img class="img-filtered-handicap" src="${filter.img}" alt="${filter.name}">
                <h2 class="title-filtered">${filter.name}</h2>
            </a>
        `;
        return filterElement;
    }

    // Fonction pour démarrer le défilement automatique
    function startAutoScroll() {
        clearInterval(autoScrollInterval);
        autoScrollInterval = setInterval(() => {
            scrollFilters();
        }, 30); // Ajustez la vitesse ici
    }

    // Fonction pour stopper et relancer le défilement automatique après inactivité
    function stopAutoScroll() {
        clearInterval(autoScrollInterval);
        clearTimeout(userInteractionTimeout);
        userInteractionTimeout = setTimeout(startAutoScroll, 5000); // Redémarre après 5 secondes
    }

    // Fonction pour faire défiler les filtres et gérer le retour au début
    function scrollFilters() {
        const scrollAmount = 1;
        filtersWrapper.scrollLeft += scrollAmount;

        // Vérifie si le défilement atteint la fin des filtres d'origine
        if (filtersWrapper.scrollLeft >= filterContainer.scrollWidth / 2) {
            filtersWrapper.scrollLeft = 0; // Repositionne au début pour un défilement infini
        }
    }

    // Arrêter le défilement automatique lors du survol des filtres
    filtersWrapper.addEventListener('mouseenter', stopAutoScroll);
    filtersWrapper.addEventListener('mouseleave', startAutoScroll);

    // Chargement des filtres et démarrage du défilement automatique
    loadFilters();
    startAutoScroll();
});
