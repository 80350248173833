document.addEventListener('DOMContentLoaded', function () {
    const scrollContainer = document.querySelector('.financial-help-posts');

    // Vérifier si l'élément existe avant d'exécuter le reste du script
    if (!scrollContainer) {
        return; // Stoppe l'exécution du script si l'élément n'est pas trouvé
    }

    let autoScrollInterval;
    let userInteractionTimeout;

    // Charger les articles une seule fois
    function loadFinancialHelpPosts() {
        // Effectuer la requête AJAX pour récupérer les articles
        fetch('/financial-help-posts')
            .then(response => response.json())
            .then(posts => {
                // Ajouter les articles à la liste et les dupliquer
                posts.forEach((post) => {
                    scrollContainer.appendChild(createPostElement(post));
                });
                posts.forEach((post) => {
                    scrollContainer.appendChild(createPostElement(post)); // Duplication pour défilement infini
                });
            })
            .catch(error => {
                console.error('Erreur lors du chargement des articles d\'aide financière:', error);
            });
    }

    // Fonction pour créer un élément article
    function createPostElement(post) {
        const postElement = document.createElement('div');
        postElement.classList.add('financial-help-post');
        postElement.innerHTML = `
            <a href="${post.blogPostUrl}">
                <img class="img-financial-help-post" src="${post.blogPostImg}" alt="${post.blogPostTitle}" loading="lazy">
            </a>
        `;
        return postElement;
    }

    // Fonction pour démarrer le défilement automatique
    function startAutoScroll() {
        clearInterval(autoScrollInterval);
        autoScrollInterval = setInterval(() => {
            scrollContainer.scrollLeft += 1; // Ajustez la vitesse ici

            // Vérifier si on atteint la fin et revenir au début
            if (scrollContainer.scrollLeft >= scrollContainer.scrollWidth / 2) {
                scrollContainer.scrollLeft = 0;
            }
        }, 30); // Ajustez la vitesse ici
    }

    // Fonction pour stopper le défilement
    function stopAutoScroll() {
        clearInterval(autoScrollInterval);
        clearTimeout(userInteractionTimeout);
        userInteractionTimeout = setTimeout(startAutoScroll, 5000); // Redémarrer après 5 secondes
    }

    // Ajouter les événements pour arrêter et redémarrer le défilement
    scrollContainer.addEventListener('mouseenter', stopAutoScroll);
    scrollContainer.addEventListener('mouseleave', startAutoScroll);

    // Charger les articles et démarrer le défilement
    loadFinancialHelpPosts();
    startAutoScroll();
});
